import React from 'react'
import Home from '../components/Home'

const IndexPage = () => {
  return (
    <Home />
  )
}

export default IndexPage
